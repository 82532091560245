import React from 'react';
import cls from 'classnames';

const ProductSkeleton = () => (
  <li className="media-card media-card--products media-card--bones">
    <div className="media-card__outer-link">
      <div className="media-card__inner-link">
        <div className="media-card__image-and-calls-to-action">
          <div className="media-card__image-aspect-container">
            <div className="media-card__image-aspect" />
          </div>
        </div>
        <div className="media-card__main-title">
          <div className="media-card__placeholder-text">&nbsp;</div>
        </div>
      </div>

      <div className="media-card__subtitle">
        <div className="media-card__placeholder-text">&nbsp;</div>
        <div className="media-card__placeholder-text">&nbsp;</div>
      </div>
    </div>
  </li>
);

const ProductSkeletons = ({ across = 4, className }) => (
  <ul
    className={cls( `products products--with-merchant-info products--${across}-across`, className )}
  >
    {[ ...Array( across ) ].map(( x, i ) => (
      <ProductSkeleton key={i} />
    ))}
  </ul>
);

export default ProductSkeletons;
